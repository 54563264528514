import React, { useState } from 'react';
import MonacoEditor from 'react-monaco-editor';
import Editor from "./Editor";
import NavBar from './NavBar';

const LAMINAR_API_URL = "https://api.laminar.run";
const TRANSFORM_ENDPOINT = "transform/test";

const defaultLamProgram = `
# Remove some fields
.data | map(select(.value > 5))
`;

const defaultInputJson = `{
  "data": [
      {
          "value": 3
      },
      {
          "value": 6
      },
      {
          "value": 8
      },
      {
          "value": 2
      },
      {
          "value": 9
      }
  ]
}`;

const updateSessionStorage = (key, value) => {
  window.sessionStorage.setItem(key, value);
}

function App() {
  const [inputJson, setInputJson] = useState(window.sessionStorage.getItem("inputJson") || defaultInputJson);
  const [output, setOutput] = useState({});

  const [lamProgram, setLamProgram] = useState(window.sessionStorage.getItem("lamProgram") || defaultLamProgram);
  const [apiStatus, setApiStatus] = useState({ loading: false, error: null });

  const [activeTab, setActiveTab] = useState('output');

  const handleLamProgramChange = (newValue) => {
    updateSessionStorage("lamProgram", newValue);
    setLamProgram(newValue);
  };

  const handleInputChange = (newValue) => {
    updateSessionStorage("inputJson", newValue);
    setInputJson(newValue);
  };

  const handleRun = async () => {
    setApiStatus({ loading: true, error: null });

    const stringifiedInputJson = JSON.stringify(JSON.parse(inputJson));

    try {
      const response = await fetch(`${LAMINAR_API_URL}/${TRANSFORM_ENDPOINT}`, {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          program: lamProgram,
          input: stringifiedInputJson
        })
      });

      const json = await response.json();

      setOutput(json);
      setApiStatus({ loading: false, error: null });
    }
    catch (error) {
      console.error("Error: ", error);

      setApiStatus({ loading: false, error: error.message });
    }
  };

  return (
    <div className="flex flex-col h-screen">
      <NavBar />
      <div className="flex flex-grow overflow-hidden">
        <div className="w-1/3 p-4 overflow-auto">
          <h2 className="text-xl font-bold mb-2">lam Program</h2>
          <Editor onChange={handleLamProgramChange} value={lamProgram} />
        </div>

        <div className="w-0.5 bg-gray-200"></div>

        <div className="w-1/3 p-4 overflow-auto">
          <h2 className="text-xl font-bold mb-2">Input JSON</h2>
          <MonacoEditor
            width="100%"
            height="calc(100vh - 200px)"
            language="json"
            theme="vs-light"
            value={inputJson}
            onChange={handleInputChange}
            options={{ automaticLayout: true, fontSize: 16 }}
          />
        </div>

        <div className="w-1/3 p-4 overflow-auto">
          <div>
            <button
              className="w-full text-left text-xl font-bold text-gray-800 py-2 px-4 rounded-t-lg bg-gray-100 hover:bg-gray-200 focus:outline-none focus:shadow-outline"
              onClick={() => setActiveTab(activeTab === 'output' ? '' : 'output')}
            >
              Output
            </button>
            {activeTab === 'output' && (
              <div className="whitespace-pre-wrap bg-white p-4 rounded-b-lg shadow-inner h-auto text-sm">
                {output?.result ? <pre className="overflow-x-auto">{JSON.stringify(output.result, null, 2)}</pre> : "No output available."}
              </div>
            )}
          </div>

          <div className="mt-4">
            <button
              className="w-full text-left text-xl font-bold text-gray-800 py-2 px-4 rounded-t-lg bg-gray-100 hover:bg-gray-200 focus:outline-none focus:shadow-outline"
              onClick={() => setActiveTab(activeTab === 'log' ? '' : 'log')}
            >
              Log
            </button>
            {activeTab === 'log' && (
              <div className="whitespace-pre-wrap bg-white p-4 rounded-b-lg shadow-inner h-auto text-sm">
                {output?.log ? <pre className="overflow-x-auto">{output.log}</pre> : "No logs available."}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="flex-none p-4 bg-gray-100 flex justify-between items-center">
        <button
          onClick={handleRun}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center"
          disabled={apiStatus.loading}
        >
          {apiStatus.loading ? 'Running...' : 'Run'}
        </button>
        {apiStatus.error && (
          <div className="text-red-500">
            Error: {apiStatus.error}
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
