import MonacoEditor from 'react-monaco-editor';
import { lamLanguageDefinition, lamThemeDefinition } from './lam';

const Editor = ({ value, onChange }) => {
  const editorDidMount = (editor, monaco) => {
    // Register language
    monaco.languages.register({ id: 'lam' });
    monaco.languages.setMonarchTokensProvider('lam', lamLanguageDefinition);

    // Register theme
    monaco.editor.defineTheme('lamTheme', lamThemeDefinition);
    monaco.editor.setTheme('lamTheme');
  };

  return (
    <MonacoEditor
      width="800"
      height="600"
      language="lam"
      theme="lamTheme"
      options={{ selectOnLineNumbers: true, fontSize: 16 }}
      editorDidMount={editorDidMount}
      value={value}
      onChange={onChange}
    />
  );
};

export default Editor;
