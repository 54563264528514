import React from 'react';
import Logo from './laminar.svg'; // Ensure the correct path

const NavBar = () => {
  return (
    <nav className="bg-white p-4 flex items-center justify-between shadow-md">
      <div className="flex-1 flex items-center justify-start">
        <a href="https://laminar.run" target="_blank" rel="noopener noreferrer">
          <img src={Logo} alt="Laminar Logo" className="h-8 mr-3 mb-1" />
        </a>
        <span className="text-gray-800 font-bold text-xl">Playground</span>
      </div>
      <div className="flex-1 flex justify-center">
        <a href="https://docs.laminar.run" target="_blank" rel="noopener noreferrer" className="text-gray-800 text-base px-4 py-2 hover:text-blue-500">Docs</a>
        <a href="https://app.laminar.run/" target="_blank" rel="noopener noreferrer" className="text-gray-800 text-base px-4 py-2 hover:text-blue-500">Sign In</a>
      </div>
      <div className="flex-1 flex justify-end">
        <a href="https://calendly.com/faiz-laminar/30min" target="_blank" rel="noopener noreferrer" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Book a Demo</a>
      </div>
    </nav>
  );
}

export default NavBar;
